<!--
 * @Author: wangwei
 * @Date: 2020-12-29 15:56:16
 * @LastEditTime: 2023-01-18 11:26:19
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 订单支付页面
 * @FilePath: /java-pc/src/views/buy/pay.vue
-->
<template>
    <div id="pay">
        <div class="top_info_header">
            <div class="top_logo">
                <div :style="`background-image:url(${configInfo.main_site_logo})`" :onerror="defaultImg"></div>
            </div>
            <div class="top_info_progress flex_row_end_center">
                <div class="progress_item" v-if="ifcart == 'true'">
                    <div class="progress flex_row_center_center">
                        <span class="active">1</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: true }"></div>
                        </div>
                    </div>
                    <p>购物车</p>
                </div>

                <div class="progress_item">
                    <div class="progress flex_row_center_center">
                        <span class="active">{{ ifcart == "true" ? 2 : 1 }}</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: true, current: false }"></div>
                        </div>
                    </div>
                    <p style="margin-left: -11px">确认订单</p>
                </div>

                <div class="progress_item">
                    <div class="progress flex_row_center_center">
                        <span class="active">{{ ifcart == "true" ? 3 : 2 }}</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: payComplete }"></div>
                        </div>
                    </div>
                    <p>付款</p>
                </div>

                <div class="progress_item" style="margin-left: -24px">
                    <div class="progress flex_row_end_center">
                        <span :class="{ active: payComplete }">{{ ifcart == "true" ? 4 : 3 }}</span>
                    </div>
                    <p>支付成功</p>
                </div>
            </div>
        </div>
        <div class="top_info">
            <div class="top_info_text flex_row_between_between">
                <span>订单提交成功，请您尽快付款！ 订单号： {{ pay_info.data.paySn }}</span>
                <div class="price">
                    应付金额
                    <span>{{ pay_info.data.needPay }}</span> 元
                </div>
            </div>
            <div class="top_info_text flex_row_between_between">
                <span>
                    请您在提交订单后
                    <span>24小时内</span>完成支付，否则订单会自动取消
                </span>
                <div class="show_detail pointer" @click="showDetail">{{ show_detail ? "收起详情" : "展开详情" }}</div>
            </div>
            <div class="receive_info">
                <p v-if="show_detail && pay_info.data.receiveAddress && pay_info.data.receiverName">
                    收货地址：{{ pay_info.data.receiveAddress }} 收货人：{{ pay_info.data.receiverName }}
                    {{ pay_info.data.receiverMobile }}
                </p>
                <p v-if="show_detail">商品名称：{{ buy_name_list }}</p>
            </div>
        </div>
        <div class="bg">
            <div class="pay_method" v-if="hasPayMethod && true">
                <div v-if="!show_wx_pay && isgoBnak < 2">
                    <div class="balance" v-if="balance_list.data.length > 0">
                        <img class="logo" :src="balance" alt />
                        <div class="balance_info">
                            <i
                                :class="{
                                    iconfont: true,
                                    'icon-yuanquan1': pay_info.data.balanceAvailable >= pay_info.data.needPay,
                                    'icon-duihao1': current_pay_method.data.payMethod == balance_list.data[0].payMethod,
                                    'icon-jinzhi1': pay_info.data.balanceAvailable < pay_info.data.needPay
                                }"
                                @click="
                                    pay_info.data.balanceAvailable > pay_info.data.needPay
                                        ? changePayMethod(balance_list.data[0])
                                        : ''
                                "
                            ></i>
                            <!-- <i class="iconfont i"></i> -->
                            <span class="weight big_size">使用余额支付</span>
                            <span class="avai">
                                （可用余额：
                                <span class="weight">{{ pay_info.data.balanceAvailable }}</span
                                >元，目前需要在线支付： <span class="weight">{{ pay_info.data.needPay }}</span
                                >元）余额不足？
                                <span class="weight pointer" @click="goRecharge" style="color: #168ed8">马上充值</span>
                            </span>
                        </div>
                        <div
                            class="password"
                            v-if="current_pay_method.data.payMethod == balance_list.data[0].payMethod"
                        >
                            <el-input class="password_input" placeholder="支付密码" v-model="password" show-password>
                            </el-input>
                            <p v-if="!memberInfo.hasPayPassword">
                                <span style="color: #ffb33a"
                                    ><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span
                                >
                                <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                            </p>
                            <p v-else>
                                <span class="forget_password pointer" @click="forgetPassword">忘记密码?</span>
                            </p>
                        </div>
                    </div>
                    <div class="balance other_pay_method" v-if="other_pay_list.data.length > 0">
                        <img class="logo" :src="otherPay" alt />
                        <div class="balance_info flex bankList" v-if="memberInfo.memberUserType != 0">
                            <div
                                :class="{
                                    other_pay_item: true,
                                    flex_row_around_center: true,
                                    wechat: true,
                                    other_pay_items: true
                                }"
                                v-for="(item, index) in bankList"
                                :key="index"
                                @click="changeBnak(item)"
                            >
                                <i
                                    :class="{
                                        iconfont: true,
                                        'icon-yuanquan1': ischecked != item.code,
                                        'icon-duihao1': ischecked == item.code
                                    }"
                                ></i>
                                <!-- <img :src="'../../../assets/bank/'+item.code+'.png'" alt /> -->
                                <img :src="item.img" alt="" />
                                <span>{{ item.name }}</span>
                            </div>
                            <!-- <div class="bankInput">
                                <span>请输入银行卡号</span>
                                <input class="bankInput_con" v-model="bankNum" />
                            </div> -->
                            <!-- !memberInfo.hasPayPassword&& -->
                        </div>
                        <p v-if="!memberInfo.hasPayPassword && ischecked" style="margin-top: 60px; margin-left: 97px">
                            <span style="color: #ffb33a"
                                ><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span
                            >
                            <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                        </p>
                        <p v-else style="margin-top: 60px; margin-left: 97px; width: 10px; height: 20px"></p>
                        <div class="balance_info flex" v-if="isgoBnak < 2 && memberInfo.memberUserType == 0">
                            <i
                                @click="changePayMethod('yhk')"
                                :class="{
                                    iconfont: true,
                                    iconfontss: true,
                                    'icon-yuanquan1': current_pay_method.data.payMethod != 'yhk',
                                    'icon-duihao1': current_pay_method.data.payMethod == 'yhk'
                                }"
                            ></i>
                            <div
                                :class="{ other_pay_item: true, flex_row_around_center: true, wechat: index != 0 }"
                                @click="changePayMethod('yhk')"
                            >
                                <img src="../../../assets/fx_jiesuan.png" alt />
                                <span>银行卡支付</span>
                            </div>
                            <p
                                v-if="!memberInfo.hasPayPassword && current_pay_method.data.payMethod == 'yhk'"
                                style="margin-left: 10px; margin-top: 9px"
                            >
                                <span style="color: #ffb33a"
                                    ><i class="el-icon-warning"></i>未设置支付密码，马上去设置</span
                                >
                                <router-link class="set_password pointer" :to="'/member/pwd/pay'">设置密码</router-link>
                            </p>
                        </div>
                    </div>
                    <div class="pay" @click="pay" style="cursor: pointer" v-if="isgoBnak < 2">立即支付</div>
                </div>
                <div class="wx_pay_con" v-if="show_wx_pay">
                    <p class="title" @click="hideWxPay">
                        <i class="iconfont icon-ziyuan2"></i>
                        <span>选择其它支付方式</span>
                    </p>
                    <div class="qr flex_row_around_center">
                        <div class="left flex_column_start_center">
                            <img :src="wxPayQrImg" />
                            <p>
                                使用
                                <span>微信支付</span>APP
                            </p>
                            <p>扫码完成支付</p>
                            <div class="refresh" @click="refreshWxQrcode">刷新</div>
                        </div>
                        <img class="wx_png" :src="wx_pay_tip" />
                    </div>
                </div>
            </div>
            <!-- 签署协议start -->

            <div class="pay_method" v-if="hasPayMethod && isgoBnak">
                <p class="title" @click="hideWxPays">
                    <i class="iconfont icon-ziyuan2"></i>
                    <span>选择其它支付方式</span>
                </p>
                <div class="bank_bottom_Form">
                    <!-- 绑定银行卡 start -->
                    <el-form :model="bankForm" label-width="128px" v-if="isgoBnak == 4">
                        <!-- <div class="otherPay"> <img />选择其他支付方式 </div> -->
                        <!-- <UnBind v-if="true"></UnBind  > -->
                        <el-form-item label="支付密码">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="payPwd" placeholder="请输入支付密码" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="短信验证码:">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="testnum" placeholder="请输入短信验证码" width="120px" />
                                <div class="timeOut" style="cursor: pointer" @click="getTestNumss(item)">
                                    {{ descNum1 ? descNum1 + "s后获取" : "获取验证码" }}
                                </div>
                                <!-- <div class="timeOut">120s后获取</div> -->
                            </el-col>
                        </el-form-item>
                        <el-form-item label="获取验证码手机号:">
                            <el-col :span="7" style="float: none">
                                <div>{{ memberInfo.memberMobile }}</div>
                            </el-col>
                        </el-form-item>
                        <div class="next">
                            <div @click="payNow">立即支付</div>
                        </div>
                    </el-form>
                    <!-- 绑定银行卡 end -->
                    <!-- 打款验证 start -->
                    <el-form :model="bankForm" label-width="120px" v-if="isgoBnak == 3">
                        <!-- <div class="otherPay"> <img />选择其他支付方式 </div> -->
                        <el-form-item label="付款人账号:">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerAcctNo" placeholder="请输入付款人账号" width="120px" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="付款户名">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerAcctName" placeholder="请输入付款户名" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="证件类型:">
                            <el-col :span="7" style="float: none">
                                <el-select v-model="bankForm.payerGlobalType" placeholder="请选择证件类型">
                                    <el-option label="身份证" value="01" />
                                    <el-option label="港澳回乡证" value="03" />
                                    <el-option label="台胞证" value="05" />
                                    <el-option label="中国护照" value="04" />
                                    <el-option label="外国护照" value="19" />
                                </el-select>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="证件号码">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerGlobalId" placeholder="请输入证件号码" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="预留手机号">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="bankForm.payerReserverMobile" placeholder="请输入手机号" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="短信验证码:">
                            <el-col :span="7" style="float: none">
                                <el-input v-model="messageCheckCode" placeholder="请输入短信验证码" width="120px" />
                                <!-- <div class="timeOut" @click="sendMsg">获取验证码</div> -->
                                <div class="timeOut" style="cursor: pointer" @click="getTestNum">
                                    {{ descNum ? descNum + "s后获取" : "获取验证码" }}
                                </div>
                                <!-- <div class="timeOut">120s后获取</div> -->
                            </el-col>
                        </el-form-item>
                        <div class="next">
                            <div @click="bindCard">立即申请</div>
                        </div>
                    </el-form>
                    <!-- 打款验证 end -->
                    <div v-if="isgoBnak == 2">
                        <div class="bank_content">
                            <!-- <div class="bank_content_top">我的银行卡</div> -->
                            <div class="card_list">
                                <div class="item" v-for="item in bankListSelf">
                                    <div class="bank_content_bottom">
                                        <div>{{ item.firmBankName }}</div>
                                        <div>{{ item.firmBankAccount }}</div>
                                    </div>
                                    <div
                                        :class="[bankFlag == item.id ? 'unbinding' : 'unbinding2']"
                                        @click="checkBank(item)"
                                    ></div>
                                </div>
                            </div>
                            <div class="flex_row_start_center" style="margin-top: 20px">
                                <input
                                    type="text"
                                    name=""
                                    id=""
                                    class="itemInput"
                                    v-model="bankMessage"
                                    v-if="bankinfo.data.isBind == 2"
                                />
                                <!-- <div class="timeOut2" @click="getTestNums(bankinfo.data)" v-if="bankinfo.data.isBind == 2">{{ isFlag == true ? descNum : '获取验证码' }}</div> -->
                                <div
                                    class="timeOut2"
                                    style="cursor: pointer"
                                    @click="getTestNums(bankinfo.data)"
                                    v-if="bankinfo.data.isBind == 2"
                                >
                                    {{ descNum ? descNum + "s后获取" : "获取验证码" }}
                                </div>
                            </div>
                            <div class="activation" style="cursor: pointer" @click="activationCard">
                                {{ bankinfo.data.isBind == 2 ? "立即激活" : "立即支付" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 签署协议end -->
            <!-- <div class="no_payMethod" v-else>
                <img src="@/assets/member/member_empty.png" alt="">
                <p>暂无可用的支付方式，平台正在紧急处理中～</p>
            </div> -->
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
import { ElMessage, ElInput } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import UnBind from "../../buy/unbind.vue";
import { Encrypt, Decrypt } from "@/utils/common.js";
export default {
    name: "Pay",
    components: {
        ElInput,
        UnBind
    },
    setup() {
        // 签署协议
        const bankForm = ref({
            // messageCheckCode: '', //短信验证码
            payerAcctName: "", //付款用户名
            payerAcctNo: "", //付款人账号
            payerGlobalId: "", //证件号码
            payerGlobalType: "", //证件类型
            payerReserverMobile: ""
            // resource: '',
            // desc: '',
        });
        const messageCheckCode = ref("");
        let isBinding = ref(true);
        // 签署协议end
        const ifcart = ref("");
        const balance = require("../../../assets/buy/balance.png");
        const otherPay = require("../../../assets/buy/otherPay.png");
        const ali = require("../../../assets/buy/ali.png");
        const wechat = require("../../../assets/buy/wechat.png");
        const wx_pay_tip = require("../../../assets/buy/wx_pay_tip.png");
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { proxy } = getCurrentInstance();
        const pay_method = reactive({ data: [] });
        const pay_info = reactive({ data: {} });
        const paySn = ref("");
        const current_pay_method = reactive({ data: {} });
        const balance_list = reactive({ data: [] });
        const other_pay_list = reactive({ data: [] });
        const buy_name_list = ref("");
        const password = ref("");
        const show_wx_pay = ref(false);
        const wxPayQrImg = ref(""); //微信支付二维码
        const timer = ref(0); //定时器
        const show_detail = ref(true);
        const hasPayMethod = ref(true);
        const hasFlag = ref(false);
        const configInfo = ref(store.state.configInfo);
        const memberInfo = ref(store.state.memberInfo);
        const defaultImg = ref('this.src="' + require("../../../assets/common_top_logo.png") + '"');
        const isgoBnak = ref(null); //判断是否去绑卡页面
        const bankName = ref(""); //银行名称
        console.log(memberInfo.value);
        //获取支付方式
        const getPayMethod = () => {
            proxy
                .$get("v3/integral/front/integral/orderPay/payMethod", {
                    source: "pc",
                    type: 1
                })
                .then((res) => {
                    if (res.state == 200) {
                        hasPayMethod.value = res.data.length > 0 ? true : false;
                        res.data.map((item) => {
                            if (item.payMethod == "balance") {
                                balance_list.data.push(item);
                            } else {
                                other_pay_list.data.push(item);
                            }
                        });
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //获取订单支付数据
        const getPayInfo = () => {
            proxy
                .$get("v3/integral/front/integral/orderPay/payInfo", {
                    paySn: route.query.paySn,
                    payFrom: route.query.payFrom
                })
                .then((res) => {
                    if (res.state == 200) {
                        pay_info.data = res.data;
                        buy_name_list.value = res.data.goodsNameList.join(" + ");
                    } else if (res.state == 267) {
                        ElMessage.success(res.msg + ",2s后自动跳转订单列表");
                        //清除定时器
                        if (timer.value) {
                            clearInterval(timer.value);
                            timer.value = 0;
                        }
                        setTimeout(() => {
                            router.replace({
                                path: "/member/order/list"
                            });
                        }, 2000);
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const changePayMethod = (pay_method) => {
            if (pay_method == "yhk") {
                let obj = {
                    payMethod: "yhk",
                    payMethodName: "银行卡支付",
                    payType: "YHK"
                };
                current_pay_method.data = obj;
            } else {
                ischecked.value = "";
                bankCode.value = "";
                current_pay_method.data = pay_method;
            }
        };
        // 获取银行卡列表
        const bankListSelf = ref([]);
        const getBankInfo = async () => {
            console.log(1);
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankListSelf.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankInfo();
        // 协议支付绑卡
        // 获取验证码
        const descNum = ref(0);
        const descNum1 = ref(0);
        let isFlag = ref(false);
        const getTestNum = () => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)

            // let params = {}
            let params = {};
            params = bankForm.value;
            // params.
            // proxy.$post('/v3/member/front/balanceRecharge/sendSMS', params).then(res => {
            proxy.$post("/v3/member/front/balanceRecharge/sendSMS", JSON.stringify(params), "json").then((res) => {
                if (res.state == 200) {
                    ElMessage.success(res.msg);
                    descNum.value = 120;
                    countDown();
                    isFlag.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const getTestNums = (item) => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)

            let params = {};
            params.cardId = item.cardId;
            // params.
            proxy.$get("/v3/member/front/balanceRecharge/sendSMS2", params).then((res) => {
                if (res.state == 200) {
                    ElMessage.success(res.msg);
                    descNum.value = 120;
                    countDown();
                    isFlag.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const timeOutId = ref(""); //定时器的返回值
        const timeOutId1 = ref(""); //定时器的返回值
        const isFlag1 = ref(false);
        //倒计时
        const countDown = () => {
            descNum.value--;
            if (descNum.value == 0) {
                isFlag.value = false;
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };

        //倒计时
        const countDown1 = () => {
            descNum1.value--;
            if (descNum1.value == 0) {
                isFlag1.value = false;
                clearTimeout(timeOutId1.value);
            } else {
                timeOutId.value = setTimeout(countDown1, 1000);
            }
        };
        // 激活
        const bankMessage = ref();
        const activationCard = () => {
            if (bankinfo.data.isBind == 2) {
                if (!bankMessage.value) {
                    ElMessage.warning("请输入验证码");
                    return;
                }
                let params = {};
                params.code = bankMessage.value;
                params.cardId = bankFlag.value;
                proxy.$get("/v3/member/front/balanceRecharge/agreePaySignature2", params).then((res) => {
                    if (res.state == 200) {
                        // descNum.value = 60
                        isgoBnak.value = 4;
                        ElMessage.success(res.msg);
                    } else {
                        ElMessage.warning(res.msg);
                    }
                });
            } else {
                isgoBnak.value = 4;
            }
        };
        // 提交
        const bindCard = () => {
            let param = {};
            param = bankForm.value;
            param.messageCheckCode = messageCheckCode.value;
            // param =
            // param.cardId = 111111
            proxy
                .$post("/v3/member/front/balanceRecharge/agreePaySignature", JSON.stringify(param), "json")
                .then((res) => {
                    // proxy.$post('/v3/member/front/balanceRecharge/agreePaySignature', param).then(res => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg);
                        getBankInfos();
                    } else {
                        ElMessage.warning(res.msg);
                    }
                });
        };
        const getBankInfos = async () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    descNum.value = 0;
                    clearTimeout(timeOutId.value);
                    isFlag.value = false;
                    bankFlag.value = res.data[0].id;
                    isgoBnak.value = 4;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        // 选择银行卡
        let bankFlag = ref();
        const bankinfo = reactive({ data: {} });
        const checkBank = (item) => {
            bankFlag.value = item.id;
            bankinfo.data = item;
        };
        //去支付
        const payComplete = ref(false);
        const payFlag = ref(false);
        const pay = () => {
            let param = {};
            if (memberInfo.value.memberUserType == 0) {
                if (!current_pay_method.data.payMethod) {
                    ElMessage.warning("请选择支付方式");
                    return;
                }
            }
            if (!memberInfo.value.hasPayPassword) {
                ElMessage.warning("请设置支付密码");
                hasFlag.value = true;
                return;
            }
            if (current_pay_method.data.payMethod == "yhk") {
                if (!memberInfo.value.hasPayPassword) {
                    ElMessage.warning("请设置支付密码");
                    hasFlag.value = true;
                    return;
                }
                if (memberInfo.value.memberUserType != 1) {
                    if (bankListSelf.value.length > 0) {
                        isgoBnak.value = 2;
                        return;
                    } else {
                        isgoBnak.value = 3;
                        return;
                    }
                } else {
                }
                // return
            } else if (current_pay_method.data.payMethod == "balance") {
                //余额支付
                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    return;
                }
                param.payPwd = Encrypt(password.value); //支付密码,使用余额时必传
            } else {
                if (!bankCode.value) {
                    ElMessage.warning("请选择支付方式");
                    return;
                }
                // if (!bankNum.value) {
                //     ElMessage.warning("请输入银行卡号");
                //     return
                // }
                // 企业支付

                param.payType = "B2B";
                param.payMethod = "B2B";
                param.paySn = pay_info.data.paySn;
                param.bankCode = bankCode.value;
                param.bankName = bankName.value;
                // param.bankCardNo= '';
            }

            if (!bankCode.value) {
                param.payType = current_pay_method.data.payType;
                param.payMethod = current_pay_method.data.payMethod;
            }
            param.paySn = pay_info.data.paySn;
            //余额支付
            if (param.payMethod == "balance") {
                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    return;
                }
                param.payPwd = Encrypt(password.value); //支付密码,使用余额时必传
            }
            if (payFlag.value) {
                return;
            }
            payFlag.value = true;
            //请求支付接口
            proxy
                .$post("v3/integral/front/integral/orderPay/doPay", param)
                .then((res) => {
                    if (res.state == 200) {
                        if (param.payMethod == "B2B") {
                            document.write(res.data.payData);
                            payFlag.value = false;
                        }
                        if (param.payMethod == "balance") {
                            ElMessage.success("支付成功,2s后自动跳转订单列表");
                            payComplete.value = true;
                            //清除定时器
                            setTimeout(() => {
                                payFlag.value = false;
                                router.replace({
                                    path: "/member/pointOrder/list"
                                });
                            }, 2000);
                        } else if (param.payMethod == "alipay") {
                            payFlag.value = false;
                            document.write(res.data.payData); //自动提交表单数据
                        } else if (param.payMethod == "wx") {
                            payFlag.value = false;
                            show_wx_pay.value = true;
                            wxPayQrImg.value = "data:image/png;base64," + res.data.payData; //微信支付二维码
                            // 定时查询是否支付成功
                            timer.value = setInterval(() => {
                                queryPayState();
                            }, 3000);
                            //两小时自动跳转订单列表
                            setTimeout(() => {
                                router.replace({
                                    path: "/member/pointOrder/list"
                                });
                            }, 432000000);
                        }
                    } else {
                        payFlag.value = false;
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    payFlag.value = false;
                    //异常处理
                });
        };
        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        };
        //隐藏微信支付内容
        const hideWxPay = () => {
            show_wx_pay.value = false;
        };
        const hideWxPays = () => {
            isgoBnak.value = null;
        };
        //刷新微信支付二维码
        const refreshWxQrcode = () => {
            pay();
        };
        //收起展开详情
        const showDetail = () => {
            show_detail.value = !show_detail.value;
        };
        onMounted(() => {
            paySn.value = route.query.paySn;
            ifcart.value = route.query.ifcart;
            getPayMethod();
            getPayInfo();
        });
        const goRecharge = () => {
            router.push("/member/recharge");
        };
        const forgetPassword = () => {
            router.push("/member/pwd/reset");
        };
        onUnmounted(() => {
            if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
            }
        });
        // 获取银行列表
        const bankNum = ref(); //银行卡号
        let bankList = ref([]);
        const getBankList = () => {
            proxy.$get("api/pay/common/queryBankB2b").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    res.data.forEach((item) => {
                        item.img = require(`../../../assets/bank/${item.code}.png`);
                    });
                    bankList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankList();
        const ischecked = ref("");
        const bankCode = ref(null); //bankcode
        const changeBnak = (item) => {
            current_pay_method.data = {};
            ischecked.value = item.code;
            bankCode.value = item.code;
            bankName.value = item.name;
        };
        // 选择银行卡后支付 个人
        let payPwd = ref("");
        const getTestNumss = (item) => {
            if (!payPwd.value) {
                ElMessage.success("请输入支付密码");
                return;
            }
            if (isFlag1.value == true) {
                return;
            }
            if (descNum1.value) {
                return;
            }
            isFlag1.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)

            let param = {};
            // param.paySn = paySn.value
            param.cardId = bankFlag.value;
            // param.password = payPwd.value
            param.paySn = paySn.value;
            param.type = 2;
            proxy.$get("v3/member/front/balanceRecharge/sendShortMessage", param).then((res) => {
                if (res.state == 200) {
                    ElMessage.success(res.msg);
                    descNum1.value = 60;
                    countDown1();
                    isFlag1.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag1.value = false;
                }
            });
        };
        const testnum = ref(null);
        const payFlag1 = ref(false);
        const payNow = () => {
            if (!testnum.value) {
                return ElMessage.warning("请输入验证码");
            }
            if (!payPwd.value) {
                return ElMessage.warning("请输入支付密码");
            }
            let param = {};
            param.paySn = paySn.value;
            param.cardId = bankFlag.value;
            param.payPwd = Encrypt(payPwd.value);
            param.code = testnum.value;
            param.type = 2;
            if (payFlag1.value) {
                return;
            }
            payFlag1.value = true;
            proxy.$post("/v3/member/front/balanceRecharge/pay", param).then((res) => {
                if (res.state == 200) {
                    // ElMessage.success(res.msg)
                    payFlag1.value = false;
                    ElMessage.success("支付成功,2s后自动跳转订单列表");
                    payComplete.value = true;
                    //清除定时器
                    setTimeout(() => {
                        router.replace({
                            path: "/member/order/list"
                        });
                    }, 2000);
                } else {
                    payFlag1.value = false;
                    ElMessage.warning(res.msg);
                }
            });
        };
        onMounted(() => {
            bankForm.value.payerReserverMobile = store.state.memberInfo.memberMobile;
        });
        return {
            pay_method,
            balance,
            otherPay,
            ali,
            wechat,
            pay_info,
            current_pay_method,
            balance_list,
            other_pay_list,
            changePayMethod,
            buy_name_list,
            password,
            pay,
            countDown,
            wx_pay_tip,
            show_wx_pay,
            hideWxPay,
            refreshWxQrcode,
            wxPayQrImg,
            goRecharge,
            forgetPassword,
            showDetail,
            show_detail,
            hasPayMethod,
            configInfo,
            timeOutId,
            defaultImg,
            memberInfo,
            payComplete,
            bankForm, //调试用的表单字段,
            bankList,
            getBankInfos,
            changeBnak,
            ischecked,
            bankNum,
            bankCode,
            bankListSelf,
            isgoBnak,
            getTestNum,
            isFlag,
            descNum,
            messageCheckCode,
            bindCard,
            checkBank,
            bankFlag,
            getTestNums,
            activationCard,
            payNow,
            payPwd,
            getTestNumss,
            testnum,
            bankMessage,
            bankinfo,
            hideWxPays,
            descNum1,
            countDown1,
            isFlag1,
            timeOutId1,
            hasFlag,
            bankName,
            payFlag,
            payFlag1
        };
    }
};
</script>
<!-- 签署协议表单样式 -->
<style lang="scss">
.bank_bottom_Form {
    // .el-cascader {
    //     width: 100%;
    // }

    .el-input__suffix {
        top: -4px;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 3px;

        .el-select {
            width: 100%;
        }
    }
}
</style>
<style scoped lang="scss">
#pay {
    .itemInput {
        // margin-top: 20px;
        height: 20px;
    }
    .timeOut2 {
        // bottom: 0px;
        // left: 330px;
        // right: 30px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        margin-left: 15px;
        cursor: pointer;
    }
}

.bankInput {
    position: absolute;
    // height: 30px;
    display: block;
    bottom: -50px;
    left: 100px;

    span {
        margin-right: 10px;
        font-size: 14px;
    }

    .bankInput_con {
        height: 30px;
    }
}

.bankInputs {
    position: absolute;
    // height: 30px;
    display: block;
    bottom: -85px;
    left: 100px;
}

.bankList {
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.activation {
    width: 133px;
    height: 49px;
    line-height: 49px;
    border-radius: 4px;
    background-color: rgba(240, 39, 39, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
    margin-top: 85px;
}

.card_list {
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;

    .item {
        position: relative;
        margin-right: 10px;
        margin-top: 30px;
    }

    .unbinding {
        position: absolute;
        top: -18px;
        right: 2px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../../assets/store_select.png);
        background-size: cover;
    }
    .unbinding2 {
        position: absolute;
        top: -18px;
        right: 2px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../../assets/store_not_select.png);
        background-size: cover;
    }
}

.bank_content {
    margin-left: 20px;

    .bank_content_top {
        height: 50px;
        line-height: 50px;
        color: #1c1c1c;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
    }

    .bank_content_bottom {
        width: 307px;
        height: 142px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: SourceHanSansSC-bold;
            font-weight: 600;
            margin-left: 20px;

            &:nth-child(2) {
                margin-top: -20px;
            }
        }
    }
}

.bank_bottom_Form {
    padding-left: 60px;
    position: relative;

    .timeOut {
        position: absolute;
        top: 5px;
        left: 330px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next {
        width: 90px;
        background-color: red;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        margin-left: 110px;
        margin-top: 40px;
    }
}
</style>
<style lang="scss">
@import "../../../style/pay.scss";
@import "../../../style/base.scss";

.no_payMethod {
    margin: 0 auto;
    width: 1200px;
    height: 560px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 163px;
        height: 114px;
    }

    p {
        margin-top: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}
</style>
